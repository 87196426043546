<div>
  <h5 class="fnt-size18px text-heading-grey mb-3">{{'PATIENT_DETAILS_RESPECT_DATA_PRIVACY_LAWS' | translate}}</h5>
  <form [formGroup]="patientForm">
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label for="patientId" class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"PATIENT_ID" | translate}}
              <span class="text-danger">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="patientId" type="number" class="form-control fnt-size14px" (keyup)="patientDetailsErrorMessages=''"
              placeholder="{{'ENTER_PATIENT_ID' | translate}}" (keyup.enter)="searchPatient(form.patientId.value)" dngxDigitOnly
              [attr.disabled]="patientForm?.get('patientId')?.disabled || aeCurrentStatus === aeReportStatus.RE_REVIEW || checkForProcess() ? true:null"/>
          </div>
          <div *ngIf="patientIdErr" class="text-danger fnt-size12px">
            {{"PATIENT_ID_NOT_VALID" | translate}}
          </div>
          <div *ngIf="form.patientId.touched && form.patientId.invalid" class="text-danger fnt-size12px">
            {{"PATIENT_ID" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
          <div *ngIf="patientDetailsErrorMessages" class="text-danger fnt-size12px">
            {{this.patientDetailsErrorMessages | capitalize : "sentenceCase" }}
          </div>
        </div>
      </div>  
      <div class="col-md-8">
        <div class="form-group">
          <label for="" class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"REGISTERED_PROGRAM" | translate}}
              <span class="text-danger">*</span>
            </span>
          </label>
          <div class="position-relative program_dropdown">
            <p-autoComplete class="w-100" [(ngModel)]="selectedPharmaProgram" [ngModelOptions]="{standalone: true}" (onSelect)="userInput('registeredProgramName','id',$event)" [dropdown]="true"
            [disabled]="aeCurrentStatus === aeReportStatus.RE_REVIEW"
                    [inputStyleClass]="'form-control fnt-size14px w-100'" [suggestions]="filterRegisteredProgramList" [disabled]="(patientForm?.get('registeredProgramName')?.disabled)"
                    [styleClass]="patientForm?.get('registeredProgramName').touched && patientForm?.get('registeredProgramName').invalid ? 'pcomponent-invalid w-100' : 'w-100'"
                    placeholder="{{'REGISTERED_PROGRAM_NAME' | translate}}" [minLength]="1" (completeMethod)="filterRegisteredProgram($event)" [autoHighlight]="true"
                     field="name" [completeOnFocus]="true" >
                    <ng-template let-program pTemplate="selectedItem">
                      <div *ngIf="selectedGender" [innerHTML]="program.name "></div>
                    </ng-template>
           </p-autoComplete>
          </div>
          <div *ngIf="form.registeredProgramName.touched && form.registeredProgramName.invalid"
            class="text-danger fnt-size12px">
            {{"REGISTERED_PROGRAM" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"PATIENT_INITIALS" | translate}}
              <span class="text-danger">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="patientInitials" type="text" class="form-control fnt-size14px" (keyup)="userValidation('patientInitials', $event)"
              placeholder="{{'ENTER_PATIENT_INITIALS' | translate}}" [maxlength]="20" [attr.disabled]="patientForm?.get('patientInitials')?.disabled?true:null"/>
          </div>
          <div *ngIf="form.patientInitials.touched && form.patientInitials.invalid" class="text-danger fnt-size12px">
            {{"PATIENT_INITIALS" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"DOB" | translate}}
              <span class="text-danger" *ngIf="!checkForProcess()">*</span>
            </span>
          </label>
          <div class="calendar-field w-100">
            <p-calendar [maxDate]="currentDate" [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true" [touchUI]="false" (ngModelChange)="calculateAge($event)"
              yearRange="1922:2030" [inline]="false" panelStyleClass="bg-white border" [disabled]="patientForm?.get('dateOfBirth')?.disabled || !checkForProcess()"
              [styleClass]="form.dateOfBirth.touched && form.dateOfBirth.invalid ? 'pcomponent-invalid w-100' : 'w-100'" dateFormat="dd M yy"
              [inputStyleClass]="'form-control fnt-size12px fnt_Medium w-100 rounded'"
              formControlName="dateOfBirth" placeholder="{{'DOB' | translate}}">
            </p-calendar>
          </div>
          <div *ngIf="form.dateOfBirth.touched && form.dateOfBirth.invalid && !checkForProcess()" class="text-danger fnt-size12px">
            {{"DOB" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-4" *ngIf="!checkForProcess() && !checkForServierProcess()">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"PREVIOUS_ID" | translate}}
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="previousId" type="text" class="form-control fnt-size14px"
              placeholder="{{'DKSH_ID' | translate}}" [attr.disabled]="true"/>
          </div>
          <div *ngIf="form.previousId?.touched && form.previousId?.invalid" class="d-none text-danger fnt-size12px">
            {{"PREVIOUS_ID" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"GENDER" | translate}}
              <span class="text-danger" *ngIf="!checkForProcess()">*</span>
            </span>
          </label>
          <div class="position-relative program_dropdown">
            <p-autoComplete class="w-100" [(ngModel)]="selectedGender" [ngModelOptions]="{standalone: true}" (onSelect)="userInput('patientGender','name', $event)" 
                    [dropdown]="true" (input)="onInputData($event)"
                    [inputStyleClass]="'form-control fnt-size14px w-100'" [suggestions]="filteredGenderList" [disabled]="patientForm?.get('patientGender')?.disabled"
                    [styleClass]="patientForm?.get('patientGender').touched && patientForm?.get('patientGender').invalid ? 'pcomponent-invalid w-100' : 'w-100'"
                    placeholder="{{'PATIENT_GENDER' | translate}}" [minLength]="1" (completeMethod)="filterGenderListData($event)" [autoHighlight]="true"
                    [completeOnFocus]="true" field="name" [inputStyle]="{ pointerEvents: 'none' }" [forceSelection]="true">
                    <ng-template let-gender pTemplate="item">
                      <div [innerHTML]="gender.name "></div>
                    </ng-template>
           </p-autoComplete>
          </div>
          <div *ngIf="form.patientGender.touched && form.patientGender.invalid && !checkForProcess()" class="text-danger fnt-size12px">
            {{"GENDER" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"AGE" | translate}} 
              <span class="text-danger" *ngIf="!checkForProcess()">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="patientAge" type="number" class="form-control fnt-size14px"
              placeholder="{{'ENTER_PATIENT_AGE' | translate}}" dngxDigitOnly [attr.disabled]="true"/>
          </div>
          <div *ngIf="form.patientAge.touched && form.patientAge.invalid && !checkForProcess()" class="text-danger fnt-size12px">
            {{"AGE" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{'COUNTRY_WHERE_EVENT_OCCURRED' | translate}}
              <span class="text-danger">*</span>
            </span>
          </label>
          <select class="form-control fnt-size12px fnt_Medium bg-porcelain-white" formControlName="country">
            <option *ngFor="let country of countryList" [value]="country.name" [selected]="country.name === form.country?.value"
              class="mt-5">
              {{country.name}}
            </option>
          </select>
          <div *ngIf="(form.country.touched) && form.country.invalid" class="text-danger fnt-size12px">
            {{'COUNTRY_WHERE_EVENT_OCCURRED' | translate}} {{'IS_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"HEIGHT_CM" | translate}}
              <span class="text-danger d-none">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="height" type="number" class="form-control fnt-size14px"
              placeholder="{{'ENTER_PATIENT_HEIGH' | translate}}" dngxDigitOnly [decimal]="true"/>
          </div>
          <div *ngIf="form.height.touched && form.height.invalid && !form.height.hasError('pattern')" class="d-none text-danger fnt-size12px">
            {{"HEIGHT_CM" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
          <div *ngIf="form.height.touched && form.height.hasError('pattern') && form.height.invalid" class="text-danger fnt-size12px">
            {{"HEIGHT_MUST_BE_CM" | translate}}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"WEIGHT_KG" | translate}}
              <span class="text-danger d-none">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="weight" type="number" class="form-control fnt-size14px"
              placeholder="{{'ENTER_PATIENT_WEIGHT' | translate}}" dngxDigitOnly [decimal]="true"/>
          </div>
          <div *ngIf="form.weight.touched && form.weight.invalid && !form.weight.hasError('pattern')" class="d-none text-danger fnt-size12px">
            {{"WEIGHT_KG" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
          <div *ngIf="form.weight.touched && form.weight.hasError('pattern') && form.weight.invalid" class="text-danger fnt-size12px">
            {{"WEIGHT_MUST_BE_KG" | translate}}
          </div>
        </div>
      </div>
      <div class="col-md-4" *ngIf="!this.checkForServierProcess()">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"ETHNIC_ORIGIN_RACE" | translate}}
              <span class="text-danger" *ngIf="!checkForProcess()">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="ethnicOrigin" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_ETHNIC_ORIGIN_RACE' | translate}}" [maxlength]="20" />
          </div>
          <div *ngIf="form.ethnicOrigin?.touched && form.ethnicOrigin?.invalid && !checkForProcess()" class="text-danger fnt-size12px">
            {{"ETHNIC_ORIGIN_RACE" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <hr>
      <div class="col-md-12">
        <div class="form-group">
          <label class="fnt-size12px">
            {{ 'REPORT_TYPE' | translate }}
            <span class="text-danger" *ngIf="!checkForProcess()">*</span>
          </label>
          <div class="custom-checkbox">
            <span class="mr-3">
              <input type="radio" class="radio-green p-2 mr-2" id="reportTypeinitial" value="initial"
                formControlName="reportType">
              <label class="radio-button-text cursor-pointer" for="reportTypeinitial">
                {{ 'INITIAL' | translate }}
              </label>
            </span>
            <span class="mr-3">
              <input type="radio" class="radio-green p-2 mr-2" id="reportTypefollowUp" value="followUp"
                formControlName="reportType">
              <label class="radio-button-text cursor-pointer" for="reportTypefollowUp">
                {{ 'FOLLOW_UP' | translate}}</label>
            </span>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
