/**
 * Angular imports.
 */
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
/**
 * Environment import.
 */
import { environment } from '../environments/environment';

/**
 * Modules import.
 */
import { AppRoutingModule } from './app-routing.module';
import { DigitOnlyModule } from 'projects/ngp-docquity-ds/src/lib/digit-only/digit-only.module';
import { DropDownModule } from 'projects/ngp-docquity-ds/src/lib/drop-down/drop-down.module';
import { ModelsModule } from 'projects/ngp-docquity-ds/src/lib/models/models.module';
import { TranslateModule } from 'projects/ngp-docquity-ds/src/lib/i18/translate.module';
import { DocGalariaModule } from 'projects/ngp-docquity-ds/src/lib/galaria/galaria.module';
import { AutoCompleteFormModule } from 'projects/ngp-docquity-ds/src/lib/auto-complete-form/auto-complete-form.module';
import { PopoverModule } from 'projects/ngp-docquity-ds/src/lib/popover/popover/popover.module';
import { RippleModule } from 'primeng/ripple';
import { OverlayPanelModule } from 'primeng/overlaypanel';

/**
 * Import pipes.
 */
import { HighlightPipe } from '../../../ngp-docquity-ds/src/lib/pipes/highlightSearchText/highlight.pipe';
import { CapitalizePipe } from '../../../ngp-docquity-ds/src/lib/pipes/capitalizeText/capitalize.pipe';

/**
 * NgRx Store Effects
 */
import { StoreModule } from '@ngrx/store';
import { LoginEffects } from './store/effects/login/login.effects';
import { SignupEffects } from './store/effects/signup/signup.effects';
import { FileEffects } from './store/effects/file/file.effects';
import { HybridEffects } from './store/effects/hybrid/hybrid.effects';
import { DoctorEffects } from './store/effects/doctor/doctor.effects';
import { PharmaEffects } from './store/effects/pharma/pharma.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { reducers, metaReducers } from './store/reducers';

/**
 * Interceptor imports.
 */
import { httpInterceptorProviders } from '../app/interceptors';

/**
 * PrimeNg Modules.
 */
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CaptchaModule } from 'primeng/captcha';
import { DialogModule } from 'primeng/dialog';
import { SidebarModule } from 'primeng/sidebar';
import {ButtonModule} from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { CardModule } from 'primeng/card';
import { ToastrModule } from 'projects/ngp-docquity-ds/src/lib/toastr/toastr.module';
/**
 * module imports.
 */
import { NgHcaptchaModule } from 'ng-hcaptcha';

/**
 * Components import.
 */
import { AppComponent } from './app.component';
import { SignInComponent } from './components/main/sign-in/sign-in.component';
import { MainComponent } from './components/main/main.component';
import { SignUpChatbotComponent } from './components/main/sign-up-chatbot/sign-up-chatbot.component';
import { PatientFormComponent } from './components/main/sign-up-chatbot/patient-form/patient-form.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AeReportingComponent } from './share/ae-reporting/ae-reporting.component';
import { CreatePasswordComponent } from './components/main/create-password/create-password.component';
import { CreatePasswordSuccessComponent } from './components/main/create-password-success/create-password-success.component';
import { ResetPasswordComponent } from './components/main/reset-password/reset-password.component';
import { HybridComponent } from './components/main/hybrid/hybrid.component';
import { LoginComponent } from '../app/components/main/doctor/login/login.component';
import { OtpVerifyComponent } from './components/main/doctor/otp-verify/otp-verify.component';
import { AddDoctorComponent } from './components/main/doctor/add-doctor/add-doctor.component';
import { ViewDoctorComponent } from './components/main/doctor/view-doctor/view-doctor.component';
import { PharmaViewComponent } from './components/main/pharma-view/pharma-view.component';
import { PharmaStoreComponent } from './components/main/pharma-store/pharma-store.component';
import { ContactUsComponent } from './components/main/contact-us/contact-us.component';
import { DoctorContactComponent } from './components/main/doctor/doctor-contact/doctor-contact.component';
import { DoctorHeaderComponent } from './components/main/doctor/doctor-header/doctor-header.component';
import { PharmaPolicyComponent } from './components/main/pharma-view/pharma-policy/pharma-policy.component';
import { OtpSubmitComponent } from './share/otp-submit/otp-submit.component';
/**
 * Pipe import.
 */
import { SafePipe } from 'projects/ngp-docquity-ds/src/lib/pipes/safeUrl/safe-url.pipe';
import { TimerDirective } from 'projects/ngp-docquity-ds/src/lib/directive/timer/timer.directive';
import { DoctorAeReportingComponent } from './components/main/doctor/doctor-ae-reporting/doctor-ae-reporting.component';
import { AddPatientComponent } from './components/main/patient/add-patient/add-patient.component';
import { CalendarFormModule } from 'projects/ngp-docquity-ds/src/lib/calendar-form/calendar-form.module';
import { AeReportComponent } from './components/main/ae-report/ae-report.component';
import { PatientDetailsFormComponent } from './components/main/ae-report/form-components/patient-details-form/patient-details-form.component';
import { PediatricReportFormComponent } from './components/main/ae-report/form-components/pediatric-report-form/pediatric-report-form.component';
import { AdverseEventInfoFormComponent } from './components/main/ae-report/form-components/adverse-event-info-form/adverse-event-info-form.component';
import { SuspectMedInfoFormComponent } from './components/main/ae-report/form-components/suspect-med-info-form/suspect-med-info-form.component';
import { ConcomitantMedicationInfoFormComponent } from './components/main/ae-report/form-components/concomitant-medication-info-form/concomitant-medication-info-form.component';
import { SuspectMedDeviceInfoFormComponent } from './components/main/ae-report/form-components/suspect-med-device-info-form/suspect-med-device-info-form.component';
import { ConcomitantMedDeviceInfoFormComponent } from './components/main/ae-report/form-components/concomitant-med-device-info-form/concomitant-med-device-info-form.component';
import { MeasuresTokenFormComponent } from './components/main/ae-report/form-components/measures-token-form/measures-token-form.component';
import { FurtherInfoFormComponent } from './components/main/ae-report/form-components/further-info-form/further-info-form.component';
import { RelevantLaboratoryTestDataFormComponent } from './components/main/ae-report/form-components/relevant-laboratory-test-data-form/relevant-laboratory-test-data-form.component';
import { ReporterInfoFormComponent } from './components/main/ae-report/form-components/reporter-info-form/reporter-info-form.component';
import { AgentInfoFormComponent } from './components/main/ae-report/form-components/agent-info-form/agent-info-form.component';
import { FindDoctorComponent } from './components/main/doctor/find-doctor/find-doctor.component';
import { SearchDoctorComponent } from './components/main/doctor/search-doctor/search-doctor.component';
import { CarouselModule } from 'primeng/carousel';
import { ResourcesComponent } from './components/main/doctor/resources/resources.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AeReportListComponent } from './components/main/ae-report/ae-report-list/ae-report-list.component';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { UserDetailsFormComponent } from './components/main/ae-report/form-components/user-details-form/user-details-form.component';
import { AcknowledmentInfoFormComponent } from './components/main/ae-report/form-components/acknowledgment-info-form/acknowledment-info-form/acknowledment-info-form.component';

/**
 * This is the main module.
 */
@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    MainComponent,
    SignUpChatbotComponent,
    PatientFormComponent,
    PageNotFoundComponent,
    HighlightPipe,
    CapitalizePipe,
    AeReportingComponent,
    SafePipe,
    TimerDirective,
    CreatePasswordComponent,
    CreatePasswordSuccessComponent,
    ResetPasswordComponent,
    HybridComponent,
    AddDoctorComponent,
    LoginComponent,
    OtpVerifyComponent,
    ViewDoctorComponent,
    PharmaViewComponent,
    PharmaStoreComponent,
    ContactUsComponent,
    DoctorContactComponent,
    DoctorHeaderComponent,
    PharmaPolicyComponent,
    DoctorAeReportingComponent,
    OtpSubmitComponent,
    AddPatientComponent,
    AeReportComponent,
    PatientDetailsFormComponent,
    PediatricReportFormComponent,
    AdverseEventInfoFormComponent,
    SuspectMedInfoFormComponent,
    ConcomitantMedicationInfoFormComponent,
    SuspectMedDeviceInfoFormComponent,
    ConcomitantMedDeviceInfoFormComponent,
    MeasuresTokenFormComponent,
    FurtherInfoFormComponent,
    RelevantLaboratoryTestDataFormComponent,
    ReporterInfoFormComponent,
    AgentInfoFormComponent,
    FindDoctorComponent,
    SearchDoctorComponent,
    ResourcesComponent,
    AeReportListComponent,
    UserDetailsFormComponent,
    AcknowledmentInfoFormComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DropdownModule,
    SidebarModule,
    TooltipModule,
    AutoCompleteModule,
    BrowserAnimationsModule,
    DigitOnlyModule,
    ModelsModule,
    TranslateModule,
    CalendarModule,
    DialogModule,
    PopoverModule,
    RippleModule,
    OverlayPanelModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot(
      [LoginEffects, SignupEffects, FileEffects, HybridEffects, DoctorEffects, PharmaEffects]),
    DropDownModule,
    DocGalariaModule,
    AutoCompleteFormModule,
    InputNumberModule,
    RadioButtonModule,
    CaptchaModule,
    ButtonModule,
    CardModule,
    ToastrModule,
    CalendarFormModule,
    TableModule,
    CarouselModule,
    PdfViewerModule,
    PaginatorModule,
    NgHcaptchaModule.forRoot({
      siteKey: environment.hCaptchaSiteKey,
      // languageCode: 'de' // optional, will default to browser language
  }),
  ],
  providers: [httpInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
