import { AfterViewInit, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
/**
 * Angular imports.
 */
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as HomeAction from '../../../../../featureModules/dashboard/featureModules/home/store/actions/home.actions';
import * as HomeSelector from '../../../../../featureModules/dashboard/featureModules/home/store/selectors/home.selectors';
import { Subscription } from 'rxjs';
/**
 * constants imports
 */
import { COUNTRIES_LIST, TH_SPR_PROCESS, AE_REPORT_STATUS, TH_SERVIER_PROCESS } from 'projects/nga-PAP/src/app/constant/app.constant';
import { PharmaNameService } from 'projects/nga-PAP/src/app/services/pharma/pharma-name.service';

/**
 * Used to show the form for pediatric report.
 */
@Component({
  selector: 'pap-patient-details-form',
  templateUrl: './patient-details-form.component.html',
  styleUrls: ['./patient-details-form.component.scss']
})
export class PatientDetailsFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() functionality = '';
  @Input() formData = null;
  @Input() disableField: any;
  @Input() process: string;

  @Input() aeCurrentStatus = '';
  /**
   * Form pediatric report.
   */
  public patientForm: FormGroup;

  public aeReportStatus  = AE_REPORT_STATUS;

  /**
   * setter for the form controls.
   */
  get form(): { [key: string]: AbstractControl } { return this.patientForm.controls; }
  get formValidity(): boolean { return this.patientForm.valid; }
  get formValues(): { patientForm: any; } { return { patientForm: this.patientForm.getRawValue() }; }

  public countryList = [{ id: 1, name: 'Thailand' }];
  public patientIdErr: boolean;
  public disabled: boolean;

  public filterRegisteredProgramList = [];
  public registeredProgramList = [];
  public selectedPharmaProgram = { id: '', name: '', code: '' };

  public filteredGenderList: any;
  public genderList = [
    { name: 'Male', value: 'MALE' },
    { name: 'Female', value: 'FEMALE' },
    { name: 'Prefer not to answer', value: 'OTHER' }
  ];
  public selectedGender = { name: '', value: '' };

  private subscriptions: Subscription = new Subscription();
  private pharmaNameChangeSub: Subscription;


  /**
   * patient detail error messages
   */
  public patientDetailsErrorMessages: string;
  /**
   * current date
   */
  public currentDate = new Date();
  /**
   * Necessary instances.
   */
  constructor(private fb: FormBuilder, private store: Store, private pharmaNameChange: PharmaNameService) {
    this.store.dispatch(HomeAction.resetSearchedPatientDetail());

  }


  /**
   * Calling initialize form.
   */
  ngOnInit(): void {
    this.patientDetailsErrorMessages = '';
    this.fetchPharmaRegisteredProgram();
    this.subscriptions.add(this.store.select(HomeSelector.getPatientDetail)
      .subscribe(patientDetail => {
        if (patientDetail) {
          this.patientIdErr = false;
          if (patientDetail && patientDetail?.length === 0) {
            this.patientIdErr = true;
          }
          if (patientDetail.programName) {
            this.form.registeredProgramName.setValue(patientDetail.programName);
          }
          if (patientDetail.dob !== null || patientDetail.dob !== undefined) {
            this.form.dateOfBirth.setValue(new Date(patientDetail.dob * 1000));
          }
          if (patientDetail.gender) {
            this.form.patientGender.setValue(patientDetail.gender);
          }
          if (patientDetail.programShortCode) {
            this.form.registeredProgramCode.setValue(patientDetail.programShortCode);
          }
          if (patientDetail.patientInitials) {
            this.form.patientInitials.setValue(patientDetail.patientInitials);
          }
          if (patientDetail.age !== null || patientDetail.age !== undefined) {
            this.form.patientAge.setValue(patientDetail.age);
          }
          if (patientDetail.previousId) {
            this.form.previousId.setValue(patientDetail.previousId);
          } else {
            this.form.previousId.setValue('NA');
          }
          if (patientDetail.programId) {
            this.form.programId.setValue(patientDetail.programId);
          }
          this.setSelectedCountry();
          this.setSelectedValue();
        }
      })
    );
    /**
     * handle error on search patient
     */
    this.subscriptions.add(this.store.select(HomeSelector.getHommeError).subscribe(res => {
      if (res) {
        this.patientDetailsErrorMessages = res?.error?.messages?.toString();
      }
    }));

    /**
     * handle the pharma name change to call program api
     */
    this.pharmaNameChangeSub = this.pharmaNameChange.data$.subscribe(res => {
      if (res != null){
        this.fetchPharmaRegisteredProgram();
        this.selectedPharmaProgram = { name: '', id: '', code: '' };
      }
    });
    this.disabled = this.functionality === 'view';
    this.initializeForm();
  }
  /**
   * set selected value
   */
  setSelectedValue(): void {
    if (this.formData?.patientGender || this.patientForm.get('patientGender').value) {
      this.filteredGenderList = this.genderList;
      const gender = this.patientForm.get('patientGender').value;
      const filterOption = this.genderList.find(option => option.value === gender);
      this.selectedGender = filterOption;
    }
    if (this.patientForm.get('registeredProgramName').value) {
      const pharmaProgram = this.patientForm.get('registeredProgramName').value;
      const filterOption = this.filterRegisteredProgramList.find(option => option.name === pharmaProgram);
      this.selectedPharmaProgram = filterOption;
    }
    if (this.disableField?.patientId !== 0) {
      this.patientForm.get('patientId')?.setValue(this.disableField?.patientId);
    }
  }

  /**
   * to detect changes on incoming form values
   * @param changes changes on formData
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.formData || changes?.disableField) {
      this.initializeForm();
      this.setSelectedValue();
    }
    /**
     * when change in process
     */
    if (changes?.process) {
      this.updateFormValidation();
    }
  }

  /**
   * update the form validation wrt
   * change in process
   */
  private updateFormValidation(): void {
    if (this.patientForm && this.process) {
      if (this.checkForServierProcess())
        {
          this.patientForm.removeControl('ethnicOrigin');
        }
    }
  }

  /**
   * calculate age based on date of birth
   * @param event input age
   */
  calculateAge(event: any): void {
    if (event) {
      const birthDate = new Date(event);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      // If birth date is greater than today's date then decrement age by 1
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      this.patientForm.get('patientAge').setValue(age);
    }
    else {
      this.patientForm.get('patientAge').setValue(null);
    }
  }

  /**
   * Initializes the patient form with default values and validators.
   */
  initializeForm(): void {
    const formData = this.formData || {};

    // Determine if the form should be disabled based on various conditions
    const isFormDisabledForNewPatient = this.disabled
                                  || this.disableField.createPatient
                                  || this.disableField.existingUser;
    const isFormDisabledForExistingPatient = this.disabled
                                  || this.disableField.existingUser || !this.checkForProcess();
    const isPatientIdRequired = !(this.disableField.createPatient
                                  || this.disableField.existingUser);
    this.selectedPharmaProgram = { id: '', name: '', code: '' };

    // Initialize the patient form group
    this.patientForm = this.fb.group({
      patientId: new FormControl(
        { value: formData?.patientId || 0, disabled: isFormDisabledForNewPatient },
        isPatientIdRequired ? [Validators.required] : []),
      registeredProgramName: new FormControl(
        { value: formData?.registeredProgramName || '', disabled: isFormDisabledForExistingPatient },
        Validators.required),
      patientInitials: new FormControl(
        { value: formData?.patientInitials || '', disabled: this.disabled || !this.checkForProcess() },
        [Validators.required, Validators.maxLength(20)]),
      previousId: new FormControl({ value: formData?.previousId || '', disabled: this.disabled }),
      dateOfBirth: new FormControl(
        { value: formData?.dateOfBirth ? new Date(formData.dateOfBirth) : '', disabled: this.disabled }),
      patientGender: new FormControl(
        { value: formData?.patientGender || 'NA', disabled: this.disabled || !this.checkForProcess() },
        !this.checkForProcess() ? [Validators.required] : null),
      patientAge: new FormControl(
        { value: formData?.patientAge !== null || formData.patientAge !== undefined ? formData.patientAge : '',
          disabled: isFormDisabledForExistingPatient },
        !this.checkForProcess() ? [Validators.required] : null),
      registeredProgramCode: new FormControl(formData?.registeredProgramCode || ''),
      programId: new FormControl(formData?.programId || ''),
      country: new FormControl(
        { value: formData?.country || '', disabled: this.disabled }, Validators.required),
      height: new FormControl(
        { value: formData?.height || '', disabled: this.disabled },
        [Validators.pattern(/^\d+(\.\d{1,2})?$/)]),
      weight: new FormControl(
        { value: formData?.weight || '', disabled: this.disabled },
        [Validators.pattern(/^\d+(\.\d{1,2})?$/)]),
      ethnicOrigin: new FormControl(
        { value: formData?.ethnicOrigin || '', disabled: this.disabled },
        !this.checkForProcess() ? [Validators.maxLength(20), Validators.required] : [Validators.maxLength(20)]),
      reportType: new FormControl(
        { value: formData?.reportType || '', disabled: this.disabled },
        !this.checkForProcess() ? [Validators.required] : null),
    });
    this.updateFormValidation();
  }

  /**
   * Search the patient.
   */
  searchPatient(key: string): void {
    this.patientDetailsErrorMessages = '';
    if (key) {
      this.store.dispatch(HomeAction.getSearchedPatientId({
        id: key,
        pharmaCode: localStorage.getItem('pharmaCode') ? localStorage.getItem('pharmaCode') : ''
      }));
    }
  }

  /**
   * Function to handle user input and restrict only leading/trailing blank spaces.
   * @param fieldName The name of the form field to update.
   * @param valueSource The source of the value ('id' or 'name').
   * @param event The input event object.
   */
  userInput(fieldName: string, valueSource: string, event: any): void {
    let value: string;
    if (valueSource === 'id') {
      value = event.id;
      this.patientForm.get('programId')?.setValue(value);
      this.patientForm.get('registeredProgramCode')?.setValue(event.code);
      value = event.name.trimStart();
    } else if (valueSource === 'name') {
      value = event.value.trimStart();
    }
    this.patientForm.get(fieldName).setValue(value);
  }

  /**
   * function to restrict only blankspaces entry.
   */
    userValidation(fieldName: string, event: any): void {
      if (event.target.value) {
        const value = event.target.value;
        if (value[0] === ' ') {
          this.patientForm.get(fieldName).setValue(value.trimStart());
        }
      }
    }
  /**
   * Fetches the registered programs associated with the pharma ID.
   */
  fetchPharmaRegisteredProgram(): void {
    const pharmaId = localStorage.getItem('pharmaId');
    if (!pharmaId) { return; }

    this.store.dispatch(HomeAction.getPharmProgramList({
      keyword: '',
      limit: '200',
      pharma: pharmaId
    }));

    this.store.select(HomeSelector.getPharmaProgramList).subscribe(data => {
      if (!data) { return; }

      this.registeredProgramList = data.list?.map(obj => ({
        id: obj.Program?.Id,
        name: obj.Program.Name,
        code: obj.Program?.Code
      })) || [];
      this.filterRegisteredProgramList = this.registeredProgramList;

      const registeredProgramName = this.patientForm.get('registeredProgramName').value;
      if (registeredProgramName) {
        const filterOption = this.filterRegisteredProgramList.find(option => option.name === registeredProgramName);
        this.selectedPharmaProgram = filterOption;
      }
    });
  }

  /**
   * Filter the program list on input.
   */
  filterRegisteredProgram(event): void {
    this.filterRegisteredProgramList = this.registeredProgramList;
    const filtered: any[] = [];
    const query = event.query;

    for (const programList of this.registeredProgramList) {
      const program = programList;
      if (program.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(program);
      }
    }
    this.filterRegisteredProgramList = filtered;
  }
  /**
   * filter the gender list on input
   * @param event query data
   */

  filterGenderListData(event: any): void {
    this.filteredGenderList = this.genderList;
    const filtered: any[] = [];
    const query = event.query;

    for (const genderListItem of this.filteredGenderList) {
      const gender = genderListItem;
      if (gender.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(gender);
      }
    }
    this.filteredGenderList = filtered;
  }

  /**
   * get gender data
   */
  onInputData(event: any): void {
    const inputValue = event.target.value;
    const selectedOption = this.genderList.find(option => option.name.toLowerCase() === inputValue.toLowerCase());
    if (!selectedOption) {
      this.patientForm.get('patientGender')?.setValue('NA');
    }
  }

  /**
   * to set the selected country
   * from local storage
   */
  private setSelectedCountry(): void {
    const currentCountry = localStorage.getItem('country');
    if (currentCountry === COUNTRIES_LIST.THAILAND.value) {
      this.form.country.setValue('Thailand');
    }
  }

  /**
   * Check for a particular process
   */
  checkForProcess(): boolean {
    return this.process === TH_SPR_PROCESS;
  }

  /**
   * Unsubscribe the subscriptions.
   */
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.patientForm.reset();
  }
  /**
   * Check if process is for servier chatbot flow
   */
  checkForServierProcess(): boolean {
    return this.process === TH_SERVIER_PROCESS;
  }
}
