
/**
 * Approval list type
 */
export const APPROVAL_LIST = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  REAPPLY: 'REAPPLY',
  REVIEW_REJECT: 'REVIEW_REJECT',
  REVIEWED: 'REVIEWED',
  RE_REVIEW: 'RE_REVIEW',
  ON_HOLD: 'ON_HOLD',
  NEW: 'NEW',
  IN_REVIEW: 'IN_REVIEW',
  REAPPLIED: 'REAPPLIED',
  VERIFIED: 'VERIFIED'
};

/**
 * Chatbot constants.
 */
export const CHATBOT_CONSTANTS = {
  DOCQUITY_MESSAGE: 'DOCQUITY_MESSAGE',
  USER_MESSAGE: 'USER_MESSAGE',
  PATIENT: 'PATIENT',
  CAREGIVER: 'CAREGIVER',
  EDIT: 'Edit',
  SUBMIT: 'Submit',
  INVALID_TOKEN: '403',
  SOMETHING_WENT_WRONG: '400',
  DEFAULT_ERROR: 'DEFAULT_ERROR',
  YES: 'Yes',
  NO: 'No',
  TRUE: 'true',
  REGION: 'region',
  CITY: 'city',
  PROGRAM: 'program',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName'
};

/**
 * Chatbot block types.
 */
export const BLOCK_TYPE = {
  INFO: 'INFO',
  CONSENT: 'CONSENT',
  FORM: 'FORM',
  OPTION: 'OPTION',
  REJECT_REASONS_MESSAGE: 'REJECT_REASONS_MESSAGE',
  REJECT_APP_THANKYOU_MESSAGE: 'REJECT_APP_THANKYOU_MESSAGE',
  INFO_THANKYOU_DOCTOR: 'INFO_THANKYOU_DOCTOR',
  INFO_ENROLLMENT: 'INFO_ENROLLMENT',
  INFO_CAREGIVER_THANKYOU: 'INFO_CAREGIVER_THANKYOU',
  DR_SHIFT_OPTION: 'DR_SHIFT_OPTION',
  DR_SHIFT_CONFIRMATION: 'DR_SHIFT_CONFIRMATION',
  DR_SHIFT_FAILURE: 'DR_SHIFT_FAILURE',
  EDIT_DETAIL: 'EDIT_DETAIL',
  PHONE_EMAIL_MISMATCH: 'PHONE_EMAIL_MISMATCH',
  THANK_YOU: 'THANK_YOU',
  REJECTED: 'REJECTED',
  EDIT_SUCCESS: 'EDIT_SUCCESS',
  EDIT_BUTTON: 'EDIT_BUTTON',
  ALREADY_APPROVED: 'ALREADY_APPROVED',
  DELETE_CAREGIVER_CONFIRMATION: 'DELETE_CAREGIVER_CONFIRMATION',
  ADD_NEW_CAREGIVER: 'ADD_NEW_CAREGIVER'
};

/**
 * Chatbot field type.
 */
export const FIELD_TYPE = {
  SEARCH: 'SEARCH',
  PHONE: 'PHONE',
  SELECT: 'SELECT',
  EMAIL: 'EMAIL',
  DATE: 'DATE',
  TEXT: 'TEXT',
  FILE: 'FILE',
  ADDRESS_HOUSE_NO_TEXT: 'ADDRESS_HOUSE_NO_TEXT',
  ADDRESS_SUBDIVISION_TEXT: 'ADDRESS_SUBDIVISION_TEXT',
  MULTIPLE_FILE: 'MULTIPLE_FILE',
  NUMBER: 'NUMBER',
  LOCATION: 'LOCATION'
};
/**
 * Chatbot field type.
 */
export const FIELD_NAME = {
  DOCTOR_NAME: 'doctorName'
};

/**
 * User type constant.
 */
export const USER_TYPE = {
  APPROVER: 'pap_approver',
  STORE_OPERATOR: 'store_operator',
  PAP_SUPERVISOR: 'pap_supervisor',
  DOCTOR: 'doctor'
};

/**
 * Error Code
 */
export const ERROR_CODE = {
  E4002: 'E4002', // ThePrescriptionHasExpiredCode
  E4040: 'E4040', // UnableToFetchData
  E4031: 'E4031', // OtpHasExpiredCode
  E4003: 'E4003', // error code for doctor opt out
  E4004: 'E4004', // error code for patient opt out
  E4005: 'E4005', // error code for both patient and doctor opt out,
  E40107: 'E40107', // error code for conflicted email
  E40108: 'E40108', // error code for conflicted phone
  E4032: 'E4032',   // error code for prescription in review,
  E4018: 'E4018', // error code for consent not provided,
  E4017: 'E4017', // mandatory pap drug is missing
  E4023: 'E4023', // error code prescription lock
  E4024: 'E4024'
};

/**
 * Used to select the available languages.
 */
export const LANGUAGES =
  [
    { label: 'ENGLISH', value: 'en' },
    { label: 'ภาษาไทย', value: 'th' },
  ];
/**
 * Ae reporting form url.
 */
export const AEREPORTING_URL =
{
  approval: 'https://airtable.com/embed/shrbt2e8hj02Fnq2I?backgroundColor=blue',
  store_operator: 'https://airtable.com/embed/shrFcWcLZawVsjAEO?backgroundColor=blue',
  store_operator_prod: 'https://airtable.com/embed/shrbt2e8hj02Fnq2I?backgroundColor=blue',
  doctor: 'https://airtable.com/embed/shr7LhR7MDslY8c63?backgroundColor=blue',
  doctor_prod: 'https://airtable.com/embed/shrdM7diLCTnrs1gq?backgroundColor=blue'
};

/**
 * File service url.
 */
export const APPROVER_CONSTANTS = {
  FILE_SERVICE_URL: '/api/v1/file',
  TIMES_A_DAY: 'Times a Day',
  TIMES_A_WEEK: 'Times a Week',
  TIMES_A_MONTH: 'Times a Month',
  DAYS: 'Days',
  WEEK: 'Weeks',
  MONTH: 'Months',
  BLANK_DOB: 'DD-MM-YYYY'
};

/**
 * Mime Types.
 */
export const MIME_TYPES = {
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  JPG: 'image/jpg',
  PDF: 'application/pdf',
  MP4: 'video/mp4',
  MOV: 'video/quicktime',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XLS: 'application/vnd.ms-excel',
  CSV: 'text/csv',
};

export const IMAGE_TYPES = {
  jpeg: 'jpeg',
  jpg: 'jpg',
  png: 'png',
  pdf: 'pdf',
  mp4: 'mp4',
  mov: 'mov',
  xlsx: 'xlsx',
  xls: 'xls',
  csv: 'csv',
};

export const PATTERN = {
  URL_SUFFIX: /\#|\?/,
  DOCTOR_NAME_REG: /^(?!\s)(?!.*\s$)*[A-Z|a-z|.,'@^`| |]+$/,
  NO_SPACE_BEGIN: /^(?!\s)(?!.*\s$)*/,
  DOCTOR_REG_PRC: /^(?!\s)(?!.*\s$)*[A-Z|a-z|0-9| |/._-]+$/,
  DRUG_NAME: /^(\w| )*[A-Z|a-z| |()-.,_']+$/,
  DOCTOR_NAME: /^[A-Z|a-z|0-9| |.,'@^`]+$/,
  EMAIL: /^(?![0-9]+@)([a-zA-Z0-9_\-\.\+]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/i,
  WHOLE_NUMBER_REGEX: /^[0-9]+$/,
  ONLY_ALPHABET: /^[a-zA-Z]*$/,
  NO_ZERO: /^[1-9][0-9]*$/,
  PRESCRIPTION_FILE_REGEX: /pap\/prescription\/.+?\./g,
  NOT_ALPHANUMERIC: /[^a-zA-Z0-9_]/g,
  URL_REGEX: /(https?:\/\/[^\s]+)/g,
  DISCOUNT_PROOFS_FILE_REGEX: /discountProofs\//g,
  ALLOW_0_RESTRICT_AFTER_0: /^-?(0|[1-9]\d*)?$/,
  OTP_FOUR:  /^[0-9]{4}$/
};

/**
 * Local storage country key.
 */
export const LOCAL_STORAGE_COUNTRY = {
  COUNTRY: 'country'
};
/**
 * Local storage country key.
 */
export const COUNTRIES_LIST = {
  INDONESIA: {
    name: 'Indonesia', value: 'ID'
  },
  PHILLIPPINS: {
    name: 'philippines', value: 'PH'
  },
  INDIA: {
    name: 'india', value: 'IN'
  },
  THAILAND : {
    name: 'thailand', value: 'TH'
  }
};
export const COUNTRIES_NAME = {
  PH: 'philippines',
  TH: 'thailand',
  ID: 'Indonesia',
  IN: 'india'
};
/**
 * Hybrid form states.
 */
export const HYBRID_STATES = {
  NA: 'NA',
  WELCOME_INFO: 'WELCOME_INFO',
  LOGIN: 'LOGIN',
  PROGRAM_INFO: 'PROGRAM_INFO',
  PATIENT_CONSENT: 'PATIENT_CONSENT',
  PRESCRIPTION_INFO: 'PRESCRIPTION_INFO',
  PATIENT_INFO_FORM: 'PATIENT_INFO_FORM',
  FILE_UPLOAD_FORM: 'FILE_UPLOAD_FORM',
  OPTION: 'OPTION',
  CARETAKER_INFO_FORM: 'CARETAKER_INFO_FORM',
  EDIT_DETAIL: 'EDIT_DETAIL',
  END: 'END',
  PHONE_EXIST: 'PHONE_EXIST',
  EMAIL_EXIST: 'EMAIL_EXIST',
  THANK_YOU_SUCCESS: 'THANK_YOU_SUCCESS',
  THANK_YOU_CARE: 'THANK_YOU_CARE',
  PATIENT_PRESCRIPTION_STATUS: 'PATIENT_PRESCRIPTION_STATUS',
  REJECTED: 'REJECTED',
  EDIT_ACTIVE: 'EDIT_ACTIVE',
  EDIT_SUCCESS: 'EDIT_SUCCESS',
  PRESCITPTION_APPROVED_SEQUENTIAL_SUCCESS: 'PRESCITPTION_APPROVED_SEQUENTIAL_SUCCESS',
  ALREADY_APPROVED: 'ALREADY_APPROVED',
  EDIT_BUTTON: 'EDIT_BUTTON',
  DELETE_CAREGIVER_CONFIRMATION: 'DELETE_CAREGIVER_CONFIRMATION',
  ADD_NEW_CAREGIVER: 'ADD_NEW_CAREGIVER',
  ADDITIONAL_INFO: 'ADDITIONAL_INFO_FORM',
  ORDER_INFO_FORM: 'ORDER_INFO_FORM',
  TREATMENT_INFO_FORM: 'TREATMENT_INFO_FORM',
  THANK_PROVIDING_DETAILS: 'THANK_PROVIDING_DETAILS',
  PATIENT_VERIFY_OTP: 'PATIENT_VERIFY_OTP',
  PATIENT_RESEND_OTP: 'PATIENT_RESEND_OTP',
};

export const APPLICATION_TYPE = {
  AUTHORIZATION: 'AUTHORIZATION',
  HYBRID: 'HYBRID',
};

export const PRESCRIPTION_TYPE = {
  GENERIC: 'GENERIC',
  DIGITAL: 'DIGITAL',
};

export const PAYMENT_METHOD = {
  PCSO_GL: 'PCSO_GL',
  CASH_CREDIT: 'CASH_CREDIT',
  FREE: 'FREE',
  OP_GL: 'OP_GL',
  OVP_GL: 'OVP_GL',
  DSWD_GL: 'DSWD_GL'
};
export const HREF_LINKS = {
  NOVARTIS_PRIVACY: 'https://docquityph.com/docquitycare/novartis-privacy-notice/'
};

export const PANEL_TITLE = {
  PAP_APPROVER: 'PAP Approver',
  PAP_SUPERVISOR: 'PAP Supervisor',
  DASHBOARD: 'Dashboard'
};

export const MAX_FILE_SIZE = 20971520;

export const DOCTOR_CONSTANTS = {
  PHONE: 'phone',
  EMAIL: 'email'
};
export const PARAMS_KEY = {
  PHARMA_CODE: 'pharmaCode'
};
export const LOCAL_STORE = {
  PHARMA_CODE: 'pharmaCode',
  PHARMA_ID: 'pharmaId'
};
export const CONTACT_US = {
  PHONE_NO: '+63 91 76 279371',
  PHONE_NO_1: '+63 9189162142',
  PHONE_NO_2: '(632) 85 40 9670',
  EMAIL_ID: 'docquitycare@docquity.com',
};
export const ROUTE_URL = {
  CONTACT_US: '/store/contact-us'
};
export const EXTRA = {
  BACK_PRESCRIPTION_YEAR: 2,
  AFTER_CURRENT_YEAR: 3
};
export const FILE_MODULE = {
  PAP: 'pap',
  NA: 'na'
};
export const PATIENT_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  NEW: 'NEW',
  REVISIT: 'REVISIT',
  ON_HOLD: 'ON_HOLD',
  WITHDRAWN: 'WITHDRAWN',
  DECEASED: 'DECEASED'
};

export const ALL_PAP_FETCH_LIMIT = '100';
export const CHATBOT_PROGRAM_FETCH_LIMIT = '10';

export const REFERER_TYPE = {
  PRESCRIPTION: 'PRESCRIPTION',
  PATIENT: 'PATIENT'
};

/**
 * AWS folder Names
 */
export const FOLDER_CONSTANTS = {
  PRESCRIPTION: 'prescription',
  DISCOUNT_PROOFS: 'discountProofs'
};

export const REQUEST_TYPE = {
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
  MOBILE: 'mobile'
};

export const APPLICATION_FORM_TYPE = {
  APPLICATION_FORM: 'Application Form',
  PROOF_OF_PURCHASE: 'Purchase proof',
  REDEMPTION_FORM: 'Redemption Form'
};

export const APPLICATION_FORM_KEYS = {
  APPLICATION_FORM: 'APPLICATION_FORM',
  PROOF_OF_PURCHASE: 'PROOF_OF_PURCHASE',
  REDEMPTION_FORM: 'REDEMPTION_FORM'
};

export const AE_FORM_TYPES = {
  PATIENT_FORM: 'patientForm',
  PEDIATRIC_REPORT_FORM: 'pediatricReportForm',
  ADVERSE_EVENT_INFO_FORM: 'adverseEventInfoForm',
  SUSPECT_MED_INFO_FORM: 'suspectMedInfoForm',
  CONCOMITANT_MED_INFO_FORM: 'concomitantMedInfoForm',
  SUSPECT_MED_DEVICE_INFO_FORM: 'suspectMedDeviceInfoForm',
  CONCOMITANT_MED_DEVICE_INFO_FORM: 'concomitantMedDeviceInfoForm',
  MEASURE_TOKEN_FORM: 'measureTokenForm',
  FURTHER_INFO_FORM: 'furtherInfoForm',
  RELEVANT_LAB_TEST_FORM: 'relevantLabTestForm',
  REPORTED_INFO_FORM: 'reportedInfoForm',
  AGENT_INFO_FORM: 'agentInfoForm',
  USER_INFO_FORM: 'userNameInfo',
  ACKNOWLEDGMENT_FORM: 'acknowledgmentForm'
};

export const UN_EMBEDDED_PHARMA = 'AM_NR_PD_1';
export const OAZIS_FLOW_PROCESS = 'TH_OAZ_NR_PND_1';
export const TH_SPR_PROCESS = 'TH_SPR_NR_PND_1';
export const TH_SERVIER_PROCESS = 'TH_SER_R_PND_1';

export const PROGRAM_DETAIL_UPLOAD = {
  UPLOAD_ORDER_FORM: 'uploadOrderForm',
  UPLOAD_HCP_FORM: 'uploadHcpForm'
};

export const TREATMENT_DETAILS = 'TREATMENT_DETAILS';

export const AE_REPORT_STATUS = {
  REVIEWED: 'REVIEWED',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
  RE_REVIEW: 'RE_REVIEW',
};

export const DATE_TYPE = {
  FOLLOW_UP: 'FOLLOW_UP',
  INTERACTION: 'INTERACTION_DATE',
  DISTRIBUTOR_INVOICE_DATE : 'DISTRIBUTOR_INVOICE_DATE'
};

export const DEFAULT_TIMER = {
  OTP_RESEND_TIME: 30000,
};
