<div class="detailsPage" *ngIf="isAeFormsVisible && checkForThailandCountry()">
  <div class="m-4">
    <div class="row">
      <div class="col-6">
        <span (click)="resetAeForm()" class="cursor-pointer">
          <img src="assets/images/back-icon.svg" alt="back icon"> Back
        </span>
      </div>
      <div class="col-6 text-right"></div>
    </div>
  </div>
  <div class="m-4 p-4 border rounded reviewStatus"  *ngIf="aeRemarks"
    [ngClass]="{
      'statusReject': aeCurrentStatus === aeStatus.REJECTED,
      'statusApproved': aeCurrentStatus === aeStatus.APPROVED,
      'statusReReview': aeCurrentStatus === aeStatus.RE_REVIEW || aeCurrentStatus === aeStatus.REVIEWED
    }">
    <p *ngIf="aeCurrentStatus !== aeStatus.REVIEWED" class="fnt-size18px font-weight-bold reviewStatus__heading">
      {{aeRemarks?.info![0]}}
    </p>
    <div *ngFor="let remark of
      aeRemarks?.info; index as i">
      <p *ngIf="i !== 0" class="mb-0 fnt-size16px">{{remark}}</p>
    </div>
    <div class="mt-2 fnt-size12px reviewStatus__time d-flex">
      <span>
        <span *ngIf="aeCurrentStatus === aeStatus.REJECTED">
          {{'REJECTED' | translate }}
        </span>
        <span *ngIf="aeCurrentStatus === aeStatus.APPROVED">
          {{'APPROVED' | translate }}
        </span>
        <span *ngIf="aeCurrentStatus === aeStatus.RE_REVIEW">
          {{'REVIEW' | translate }} {{'REQUESTED' | translate }} 
        </span>
        <span>{{'BY' | translate }} {{aeRemarks?.name}} </span>
        <span>{{'ON' | translate }} {{aeFormData?.updated_at | date:'dd MMM yyyy, hh:mm a'}}
        </span>
      </span>
    </div>
  </div>
  <form (ngSubmit)="aeReportSubmit()">
    <div class="p-4">
      <div class="ae-report" *ngIf="formsToShow[arFormTypes.USER_INFO_FORM]">
        <pap-user-details-form [aeCurrentStatus]='aeCurrentStatus' [functionality] = "pageFunctionality" [formData] = "aeReport?.userNameInfo" (fetchAeReportEvent)="setAeReportData($event)" #childForms></pap-user-details-form>
      </div>
      <div class="ae-report mt-3" *ngIf="formsToShow[arFormTypes.PATIENT_FORM]">
        <pap-patient-details-form  [aeCurrentStatus]='aeCurrentStatus' [functionality] = "pageFunctionality" [editable]="userRole === userType.APPROVER && aeCurrentStatus === aeStatus.RE_REVIEW" [formData] = "aeReport?.patientForm" [disableField]="this.disableField" [process]="process" [formStatus]="aeCurrentStatus" #childForms></pap-patient-details-form>
      </div>
      <div class="ae-report mt-3" *ngIf="formsToShow[arFormTypes.PEDIATRIC_REPORT_FORM] && !this.checkForServierProcess()">
        <pap-pediatric-report-form [functionality] = "pageFunctionality" [formData] = "aeReport?.pediatricReportForm" [formStatus]="aeCurrentStatus" #childForms></pap-pediatric-report-form>
      </div>
      <div class="ae-report mt-3" *ngIf="formsToShow[arFormTypes.ADVERSE_EVENT_INFO_FORM]">
        <pap-adverse-event-info-form [functionality] = "pageFunctionality" [formData] = "aeReport?.adverseEventInfoForm" [process]="process" #childForms></pap-adverse-event-info-form>
      </div>
      <div class="ae-report mt-3" *ngIf="formsToShow[arFormTypes.SUSPECT_MED_INFO_FORM]">
        <pap-suspect-med-info-form [functionality] = "pageFunctionality" [formData] = "aeReport?.suspectMedInfoForm" [process]="process" #childForms></pap-suspect-med-info-form>
      </div>
      <div class="ae-report mt-3" *ngIf="formsToShow[arFormTypes.CONCOMITANT_MED_INFO_FORM]">
        <pap-concomitant-medication-info-form [functionality] = "pageFunctionality" [formData] = "aeReport?.concomitantMedInfoForm" [process]="process" #childForms></pap-concomitant-medication-info-form>
      </div>
      <div class="ae-report mt-3" *ngIf="formsToShow[arFormTypes.SUSPECT_MED_DEVICE_INFO_FORM]">
        <pap-suspect-med-device-info-form [functionality] = "pageFunctionality" [formData] = "aeReport?.suspectMedDeviceInfoForm" #childForms></pap-suspect-med-device-info-form>
      </div>
      <div class="ae-report mt-3" *ngIf="formsToShow[arFormTypes.CONCOMITANT_MED_DEVICE_INFO_FORM]">
        <pap-concomitant-med-device-info-form [functionality] = "pageFunctionality" [formData] = "aeReport?.concomitantMedDeviceInfoForm" #childForms></pap-concomitant-med-device-info-form>
      </div>
      <div class="ae-report mt-3" *ngIf="formsToShow[arFormTypes.MEASURE_TOKEN_FORM]">
        <pap-measures-token-form [functionality] = "pageFunctionality" [formData] = "aeReport?.measureTokenForm" [process]="process" #childForms></pap-measures-token-form>
      </div>
      <div class="ae-report mt-3" *ngIf="formsToShow[arFormTypes.FURTHER_INFO_FORM]">
        <pap-further-info-form [functionality] = "pageFunctionality" [formData] = "aeReport?.furtherInfoForm" #childForms></pap-further-info-form>
      </div>
      <div class="ae-report mt-3" *ngIf="formsToShow[arFormTypes.RELEVANT_LAB_TEST_FORM]">
        <pap-relevant-laboratory-test-data-form [functionality] = "pageFunctionality" [formData] = "aeReport?.relevantLabTestForm" [process]="process" #childForms></pap-relevant-laboratory-test-data-form>
      </div>
      <div class="ae-report mt-3" *ngIf="formsToShow[arFormTypes.REPORTED_INFO_FORM]">
        <pap-reporter-info-form [functionality] = "pageFunctionality" [formData] = "aeReport?.reportedInfoForm" [process]="process" #childForms></pap-reporter-info-form>
      </div>
      <div class="ae-report mt-3" *ngIf="formsToShow[arFormTypes.AGENT_INFO_FORM]">
        <pap-agent-info-form [functionality] = "pageFunctionality" [formData] = "aeReport?.agentInfoForm" [process]="process" #childForms></pap-agent-info-form>
      </div>
    </div>
    <div class="d-flex p-4">
      <div class="w-50">
        <button *ngIf="userRole === userType.PAP_SUPERVISOR" [disabled]="aeCurrentStatus !== aeStatus.REVIEWED" 
          class="btn btn-outline-danger" (click)="userActionForRejection=true">
          {{'REJECT'| translate}}
        </button>
      </div>
      <div class="d-flex justify-content-end w-50">
        <button *ngIf="userRole === userType.PAP_SUPERVISOR" [disabled]="aeCurrentStatus !== aeStatus.REVIEWED" 
          class="btn btn-outline-success mx-4" (click)="userActionForSendForReview=true">
          {{'SEND_FOR_REVIEW'| translate}}
        </button>
        <button *ngIf="userRole === userType.PAP_SUPERVISOR"
          [disabled]="aeCurrentStatus !== aeStatus.REVIEWED || changeStatusLoader" class="btn btn-success"
          (click)="updateStatus(aeStatus.APPROVED, '')">
          <div class="spinner-border text-light" role="status" *ngIf="changeStatusLoader">
          </div>
          <span *ngIf="!changeStatusLoader">{{'APPROVE'| translate}}</span>
        </button>
        <button *ngIf="!userRole || userRole === userType.APPROVER" class="btn btn-success" type="submit"
          [disabled]="!updateFormValidity() || changeStatusLoader">
          <div class="spinner-border text-light" role="status" *ngIf="changeStatusLoader">
          </div>
          <span *ngIf="!changeStatusLoader">{{'SUBMIT'
            | translate}}</span>
        </button>
      </div>
    </div>
    <div class ="px-4 pb-4" *ngIf="aeCurrentStatus === aeStatus.APPROVED && formsToShow[arFormTypes.ACKNOWLEDGMENT_FORM]">
      <div class="ae-report">
        <pap-acknowledment-info-form [functionality] = "pageFunctionality" [formData] = "ackNumber"></pap-acknowledment-info-form>
      </div>
    </div>
   
  </form>
  <dngx-model class="sendForReviewApplication" [modalTitle]="'SEND_AE_FOR_REVIEW' | translate"
    [displayModal]="userActionForSendForReview" [dismissableMask]="false" [headerVisible]="true" [closable]="true"
    (hideModal)="userActionForSendForReview=false">
    <div class="pl-4 pr-4">
      <div class="pr-4">
        <textarea rows="4" class="custom-control w-100
  reasonOthertextarea" placeholder="{{'ENTER_THE_REASON' |
  translate}}" [(ngModel)]="sendForReviewRemarks"></textarea>
      </div>
    </div>
    <hr>
    <div class="d-flex justify-content-end px-3 pb-3">
      <button class="btn btn-outline-success fnt-size12px px-3 py-1 mr-3" (click)="userActionForSendForReview=false"
        [disabled]="changeStatusLoader">{{'CANCEL'
        | translate}}</button>
      
      <button *ngIf="userRole === userType.PAP_SUPERVISOR" [disabled]="!sendForReviewRemarks?.trim() || changeStatusLoader"
        class="btn btn-success fnt-size12px px-3 py-1" (click)="updateStatus(aeStatus.RE_REVIEW, sendForReviewRemarks)">
        <div class="spinner-border text-light" role="status" *ngIf="changeStatusLoader">
        </div>
        <span *ngIf="!changeStatusLoader">{{'SUBMIT'
          | translate}}</span>
      </button>
    </div>
  </dngx-model>
  <dngx-model class="rejectApplication pt-0" [modalTitle]="'REJECT_AE_REPORT' | translate"
  [displayModal]="userActionForRejection" [dismissableMask]="false" [headerVisible]="true" [closable]="true"
  (hideModal)="userActionForRejection=false">
  <div class="pl-4 pr-4">
    <div class="pr-4">
      <textarea rows="4" class="custom-control w-100
reasonOthertextarea" placeholder="{{'ENTER_THE_REASON' |
translate}}" [(ngModel)]="rejectionRemarks"></textarea>
    </div>
  </div>
  <hr>
  <div class="d-flex justify-content-end px-3 pb-3">
    <button class="btn btn-outline-success fnt-size12px px-3 py-1 mr-3" (click)="userActionForRejection=false"
      [disabled]="changeStatusLoader">{{'CANCEL'
      | translate}}</button>
    
    <button *ngIf="userRole === userType.PAP_SUPERVISOR" [disabled]="!rejectionRemarks?.trim() || changeStatusLoader"
      class="btn btn-success fnt-size12px px-3 py-1" (click)="updateStatus(aeStatus.REJECTED, rejectionRemarks)">
      <div class="spinner-border text-light" role="status" *ngIf="changeStatusLoader">
      </div>
      <span *ngIf="!changeStatusLoader">{{'SUBMIT'
        | translate}}</span>
    </button>
  </div>
</dngx-model>
  <dngx-model [displayModal]="isSuccessModal" [dismissableMask]="false" [headerVisible]="true" [closable]="true" (hideModal)="resetAeForm()"
  contentStyleClass="rounded-bottom p-0">
    <div>
      <div class="pb-4 text-center fnt-size20px text-danger">
        <img src="../../../../assets/images/success.svg" alt="">
      </div>
      <div class="text-center pb-4 pt-4">
        <span *ngIf="userActionForApproval" class="text-center fnt-size20px text-black font-weight-regular">{{'AE_REPORT_APPROVED' | translate}}
        </span>
        <span *ngIf="!userActionForApproval" class="text-center fnt-size20px text-black font-weight-regular">{{'AE_REPORT_SUBMITTED' | translate}}
        </span>
      </div>
    </div>
  </dngx-model>
</div>
